function Nav() {
  return (
    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
      <a
        href="https://github.com/markaaron88"
        className="mr-5 hover:text-gray-900"
      >
        Github
      </a>

      <a
        href="https://www.linkedin.com/in/markmariscal/"
        className="mr-5 hover:text-gray-900"
      >
        LinkedIn
      </a>

      <a
        href="https://www.youtube.com/channel/UCiVoUsKtwf3e4sk7xJf_E4A"
        className="mr-5 hover:text-gray-900"
      >
        YouTube
      </a>

      <a
        href="https://leetcode.com/Markaaron/"
        className="mr-5 hover:text-gray-900"
      >
        LeetCode
      </a>
    </nav>
  );
}

export default Nav;
