import code from "../imgs/code.jpg";
import me from "../imgs/me.jpg";
import index from "../imgs/index.png";
import petshop from "../imgs/petshop.png";
import react from "../imgs/react.png";
import java from "../imgs/java.jpg";
import unp from "../imgs/unp.png";
import nextjs from "../imgs/nextjs.png";

function Layout() {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto flex flex-col">
        <div class="lg:w-4/6 mx-auto">
          <div class="rounded-lg h-64 overflow-hidden">
            <img
              alt="content"
              class="object-cover object-center h-full w-full"
              src={code}
            />
          </div>
          <div class="flex flex-col sm:flex-row mt-10">
            <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
              <div class="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                <img alt="me" src={me} />
              </div>
              <div class="flex flex-col items-center text-center justify-center">
                <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">
                  Mark Mariscal
                </h2>
                <div class="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                <p class="text-base">
                  I am a proud member of the Society of Hispanic Professional
                  Engineers.I have lived in the Bay Area my entire life and love
                  all things technology and creating web applications.
                </p>
              </div>
            </div>
            <div class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
              <p class="leading-relaxed text-lg mb-4">
                I am a software engineer with experience designing solutions
                that enhance peoples everyday lives. Drive solutions from
                concept to prototype to implementation against aggressive
                schedules. Innovate on solutions with customers. Clear
                communications, passion for learning, results driven. Strive for
                optimal user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Projects
            </h1>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="leewazy"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={index}
                />

                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    California State University, Monterey Bay Capstone
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Leewayz
                  </h1>
                  <p class="leading-relaxed">
                    Created a full stack web application to help with solving
                    the Bay Area housing crisis with allowing for rooms to be
                    rented in a safe and ethical manner. Technologies used were
                    Express Web Framework Node.js JavaScript HTML CSS Docker
                    MYSQL DB
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="videogame"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={nextjs}
                />
                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    Personal Project
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Video Game Trailer Application
                  </h1>
                  <p class="leading-relaxed">
                    Created a web application for users to watch their favorite
                    video game trailers. This application was created using
                    Next.js and GraphQL. Created Schemas and Mutations in
                    GraphQl.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="petshop"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={petshop}
                />
                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    Freelance
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Pet Shop Web Application
                  </h1>
                  <p class="leading-relaxed">
                    Created an E-commerce web application which allows for users
                    to Sign up , select their item and purchase using an
                    external Stripe API. This application was created using
                    React, Redux, Stripe API and firebase.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="react"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={react}
                />
                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    Personal Project
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Searchable Movie Application
                  </h1>
                  <p class="leading-relaxed">
                    Created a web application for users to search for movies or
                    actors. The data is coming from an external API. The web
                    application allows for users to add a favorite actor or
                    movie and displays those favored actors and movie on its own
                    individual page. This application was created using React
                    and Sass.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="java"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={java}
                />
                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    California State University, Monterey Bay Project
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Restaurant Ordering Service
                  </h1>
                  <p class="leading-relaxed">
                    Service Oriented Architecture (SOA) design.Application was
                    created using the Java Spring Boot framework. The Web
                    application uses HTTP REST and Messaging (RabbitMQ). The
                    database used for this application was MySQl and frontend
                    was implemented using HTML and CSS.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="unp"
                  class="absolute inset-0 w-full h-full object-cover object-center"
                  src={unp}
                />
                <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    Contract Work
                  </h2>
                  <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                    Application Tool
                  </h1>
                  <p class="leading-relaxed">
                    Created a tool that allows for a user to choose data from a
                    backend database, modify the chosen data and pass that
                    modified data back to the backend data base.Created test for
                    the backend API call using python factory boy. Web
                    application was created using React and Material UI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Layout;
