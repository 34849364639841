import React from "react";
import Nav from "./nav";
import icon from "../imgs/icons.png";

function Header() {
  return (
    <header className="text-gray-600 body-font ">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          href="/"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <img alt="content" width={50} height={50} src={icon} />
          <span className="ml-3 text-xl">Mark Mariscal</span>
        </a>

        <Nav />
      </div>
    </header>
  );
}

export default Header;
